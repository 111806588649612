// services/api.ts
import axios, { AxiosError } from "axios";
import { getToken, setToken, logout } from "./authService";

// const backendUrl = 'http://localhost:3090';
console.log(
    "process.env.REACT_APP_API_BASE_URL",
    process.env.REACT_APP_API_BASE_URL
);

const backendUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3090';
const baseURL = backendUrl + "/api";
export const socketUrl = "";
// const baseURL = 'http://localhost:3090/api';

const api = axios.create({
    baseURL: baseURL, // Replace with your API URL
    headers: {
        "Content-Type": "application/json",
    },
    // withCredentials: true,
});

// Add a request interceptor to include the token
// api.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem("token");
//         if (token && config.headers) {
//             config.headers.Authorization = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (error) => Promise.reject(error)
// );

// Request interceptor to add the access token to the Authorization header
api.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token && config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor to handle token expiration and refresh
api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error instanceof AxiosError) {
            if (error.response?.data) {
                // if (error.response.status === 401) {
                //     logout();
                //     // window.location.href = '/login';
                // }
                return Promise.reject(error.response);
            }
        }
        // const originalRequest = error.config;

        // Check if the error status is 401 (Unauthorized) and not already retried
        // if (error.response.status === 401 && !originalRequest._retry) {
        //     originalRequest._retry = true;

        //     // Try to refresh the token
        //     try {
        //         const response = await axios.post(
        //             baseURL + '/auth/refresh-token',
        //             {},
        //             // { withCredentials: true }
        //         );
        //         const newAccessToken = response.data.accessToken;
        //         setToken(newAccessToken); // Save new token to localStorage or your token storage

        //         // Update original request with the new token
        //         originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

        //         // Retry the original request
        //         return axios(originalRequest);
        //     } catch (refreshError) {
        //         // If refreshing the token fails, log out the user
        //         logout();
        //         window.location.href = '/login';
        //     }
        // }
        return Promise.reject(error.response);
    }
);

interface IResponse {
    data: any;
    message: string;
    error: string;
    success: boolean;
    status: number;
}

const responseHandler = (response: any) => {
    // console.log("responseHandler response data", response.data);
    const tempData = response.data;
    return {
        data: tempData.data || "",
        message: tempData.message || "Success",
        error:
            tempData.error ||
            tempData.message ||
            "Something went wrong, please try again",
        success: tempData.success ? true : false,
        status: tempData.statusCode || 500,
    };
};

// GET REQUEST
export const getData = async (
    endpoint: string,
    id?: string | number
): Promise<IResponse> => {
    const response = await api.get(endpoint, {
        params: id ? { id } : {},
    });
    return responseHandler(response);
};

// POST REQUEST
export const postData = async (
    endpoint: string,
    data: any,
    config?: any
): Promise<IResponse> => {
    try {
        const response = await api.post(endpoint, data, config);
        console.log("return postData", response);
        return responseHandler(response);
    } catch (error: any) {
        console.log("postData catch return", error);
        return responseHandler(error);
    }
};

// PUT REQUEST
export const putData = async (
    endpoint: string,
    data: any,
    config?: any
): Promise<IResponse> => {
    const response = await api.put(endpoint, data, config);
    return responseHandler(response);
};

// DELETE REQUEST
export const deleteData = async (
    endpoint: string,
    id?: string | number
): Promise<IResponse> => {
    const response = await api.delete(endpoint, {
        params: id ? { id } : {},
    });
    return responseHandler(response);
};

// DELETE REQUEST With BODY PARAMS
export const deleteDataWithBody = async (
    endpoint: string,
    data: any
): Promise<IResponse> => {
    const response = await api.delete(endpoint, { data });
    return responseHandler(response);
};

// GET REQUEST With QUERY PARAMS
/**
 * Make a GET request to the specified endpoint with query params.
 * @param endpoint The endpoint URL
 * @param params The query params to pass
 * @returns Promise with the response data
 */
export const getDataWithQueryParams = async (
    endpoint: string,
    params: any
): Promise<IResponse> => {
    const response = await api.get(endpoint, { params });
    return responseHandler(response);
};

// POST REQUEST for file upload
export const fileUpload = async (endpoint: string, body: any, config?: any) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(baseURL + endpoint, body, {
        ...config,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return responseHandler(response);
};

export default api;
