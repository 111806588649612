export * from './downsampleBuffer';
export * from './fattens';

export const humanReadableDate = (date: string | number | Date) => {
    const d = new Date(date);
    const formatedDate = d.toLocaleString('en-US', { 
        weekday: 'short', 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric' 
      })
    console.log(formatedDate);
    return formatedDate;
    // return `${day}/${month}/${year}`;
};

// toUrlSearchParams.ts
export const toUrlSearchParams = (obj: Record<string, any>, prefix = ''): URLSearchParams => {
  const params = new URLSearchParams();

  const buildQueryString = (value: any, keyPath: string) => {
    if (value !== null && typeof value === 'object') {
      Object.entries(value).forEach(([key, val]) =>
        buildQueryString(val, `${keyPath}[${key}]`)
      );
    } else {
      params.append(keyPath, String(value));
    }
  }

  Object.entries(obj).forEach(([key, value]) => buildQueryString(value, prefix ? `${prefix}[${key}]` : key));

  return params;
}
